<template>
  <v-modal name="newIncident"
           height="auto"
           @opened="onOpened">
    <div class="NewIncidentModal">
      <div class="title w-6">New Incident</div>

      <ValidationObserver ref="observer">
        <form @submit.prevent="submit">
          <base-textbox label="Title"
                        ref="title"
                        placeholder="Describe your incident"
                        rules="required|max:255"
                        v-model="incident.title" />

          <base-select-tag label="Status"
                           v-model="selectedIncidentUpdateStatus"
                           :options="incidentUpdateStatuses" />
          <div class="row">
            <base-date-picker label="Date"
                              v-model="incident.update.date"
                              :config="datePickerConfig"
                              class="col col-6 date-picker"
                              description="If empty, the current date will be used"/>
          </div>

          <base-textarea label="Message"
                         rows="5"
                         v-model="incident.update.message"
                         rules="required"
                         :placeholder="messagePlaceholder" />

          <div class="d-flex justify-content-between align-items-center">
            <div class="toolbar">

              <base-button type="submit"
                           class="submit success"
                           :loading="saving">Publish</base-button>

              <base-button type="button"
                           color="light"
                           :outlined="true"
                           @click="$modal.hide('newIncident')">Cancel</base-button>
            </div>
            <div class="subscribers-hint">
              <!-- 54 subscribers will be notified of the incident -->
            </div>
          </div>
        </form>
      </ValidationObserver>
      <base-alert type="error" v-if="savingError" class="mt-3">
        Something went wrong
      </base-alert>
    </div>
  </v-modal>
</template>

<script>
import incidentsApi from '@/api/incidentsApi.js'
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue'

export default {
  components: {
    BaseDatePicker
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      incident: {
        title: '',
        update: {
          status: null,
          date: null,
          message: ''
        }
      },

      incidentUpdateStatuses: [
        {
          value: 'exploring',
          label: 'Exploring'
        },
        {
          value: 'identified',
          label: 'Identified'
        },
        {
          value: 'update',
          label: 'Update'
        },
        {
          value: 'resolved',
          label: 'Resolved'
        }
      ],
      selectedIncidentUpdateStatus: null,

      saving: false,
      savingError: false,
      tippy: null,

      datePickerConfig: {
        enableTime: true,
        altFormat: 'd/m/Y h:i K',
        altInput: true
      }
    }
  },

  created () {
    this.selectedIncidentUpdateStatus = this.incidentUpdateStatuses[0]
  },

  methods: {
    async submit () {
      this.savingError = false
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      this.saving = true
      try {
        let incident = await incidentsApi.store(this.statusPage.id, this.incident)
        this.$emit('submitSuccess', incident.data)
        this.$modal.hide('newIncident')
      } catch (e) {
        this.savingError = true
      }
      this.saving = false
    },

    onOpened () {
      this.$refs.title.focus()
    },

    redirectBack () {
      this.$router.push({
        name: 'statusPages.single.incidents',
        params: {
          id: this.statusPage.id
        }
      })
    }
  },

  computed: {
    messagePlaceholder () {
      switch (this.selectedIncidentUpdateStatus.value) {
        case 'exploring': return 'We are currently investigating the incident'
        case 'identified': return 'We have identified the problem'
        case 'update': return 'The issue will be fixed soon'
        case 'resolved': return 'We have resolved the issue'
      }

      return null
    }
  },

  watch: {
    selectedIncidentUpdateStatus: {
      handler: function (status) {
        this.incident.update.status = status.value
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .NewIncidentModal {
    padding: 30px;
    background-color: map-get($colors, background);

    .title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .subscribers-hint {
      color: map-get($colors, gray-2);
      font-style: italic;
    }

    .date-picker::v-deep {
      .form-control {
        padding: 21px 10px 21px 42px;
        box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
        border: 1px solid #e9ebec;
        border-radius: 6px;
        text-align: left;
      }

      &:before {
        content: '';
        position: absolute;
        top: 45px;
        left: 30px;
        transform: translateY(-50%);
        min-width: 20px;
        height: 20px;
        background: url('../../../../assets/images/calendar.svg') no-repeat;
        pointer-events: none;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 40px;
        right: 25px;
        min-width: 11px;
        height: 8px;
        background: url('../../../../assets/images/arrow_down_small.svg') no-repeat;
        pointer-events: none;
      }

      .label {
        display: block;
        margin-bottom: 10px;
        opacity: .8;
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-feature-settings: "ss04" off;
      }
    }
  }
</style>
