<template>
  <div class="card">
    <div class="card-body">
      <div>
        <div class="top d-flex justify-content-between mb-4">
          <base-button
            class="button-add"
            icon="IconAdd"
            :icon-width="20"
            :icon-height="20"
            @click="openModal"
            :disabled="!isSubscribed"
          >
            New Incident
          </base-button>
          <base-paginator
            :total="total"
            :skip="filters.skip"
            :take="filters.take"
            @prev="onPrev"
            @next="onNext"
          />
        </div>

        <table class="table" v-if="showTable">
          <colgroup>
            <col width="70%">
            <col width="30%">
          </colgroup>
          <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
          </tr>
          </thead>
          <tr v-for="incident in incidents"
              :key="incident.id">
            <td>
              <router-link :to="getIncidentRoute(incident)">{{ incident.title }}</router-link>
            </td>
            <td>
              <incident-status :status="incident.status" />
            </td>
          </tr>
        </table>

        <base-alert v-if="showNoIncidentsAlert"
                    class="mb-0">No incidents reported for this status page</base-alert>

        <div class="upgrade" v-if="!isSubscribed">
          <UpgradeBadge text="Incidents are available only for paid customers."/>
        </div>

        <incident-modal-new :status-page="statusPage"
                            @submitSuccess="loadIncidents" />
      </div>
    </div>
  </div>
</template>

<script>
import IncidentModalNew from '@/components/StatusPage/Settings/Incidents/IncidentNew.vue'
import IncidentStatus from '@/components/Common/IncidentStatus.vue'
import incidentsApi from '@/api/incidentsApi.js'
import UpgradeBadge from '@/components/Common/UpgradeBadge'

const perPage = 50

export default {
  components: {
    IncidentModalNew,
    IncidentStatus,
    UpgradeBadge
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      incidents: [],

      total: 0,
      filters: {
        skip: 0,
        take: perPage
      },

      loading: false
    }
  },

  created () {
    this.loadIncidents()
  },

  methods: {
    async loadIncidents (incident) {
      this.loading = true

      if (incident) {
        await this.$router.push(this.getIncidentRoute(incident))
      }

      const response = await incidentsApi.get(this.statusPage.id, this.filters)

      this.incidents = response.data
      this.total = response.meta.total

      this.loading = false
    },

    openModal () {
      this.$modal.show('newIncident')
    },

    goToNewIncident () {
      this.$router.push({
        name: 'statusPages.single.incidents.new',
        params: {
          id: this.statusPage.id
        }
      })
    },

    getIncidentRoute (incident) {
      return {
        name: 'statusPages.single.incidents.single',
        params: {
          id: this.statusPage.id,
          incidentId: incident.id
        }
      }
    },

    onNext () {
      this.filters.skip += this.filters.take
    },

    onPrev () {
      this.filters.skip -= this.filters.take
    }
  },

  computed: {
    showTable () {
      return this.incidents.length > 0 && !this.loading
    },

    showNoIncidentsAlert () {
      return this.incidents.length === 0 & !this.loading
    },

    isSubscribed () {
      return this.user.subscription_plan && this.user.subscription_plan.status_page_incident_updates
    },

    ...mapState('authentication', [
      'user'
    ])
  },

  watch: {
    filters: {
      handler: function () {
        this.loadIncidents()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .table {
    padding: 0;

    td {
      font-size: 16px;
    }
  }

  .upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }

  /deep/ .button-add .icon {
    margin-right: 8px;
  }
</style>
